import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const SectionWrapper = styled.section`
  background-color: rgba(240, 241, 241, 1);
  z-index: 0;
  display: flex;

 
  flex-direction: column;
  color: rgba(13, 74, 100, 1);

  padding: 80px 200px;


  @media (max-width: 1100px) {
    max-width: 100%;
    padding: 40px 24px;
    height: auto;
  }

  @media (max-width: 1100px) {
    min-height: auto; /* Override min-height for smaller screens */
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Title = styled.h2`
  text-transform:captalize;
  font: 700 34px/62px Open Sans, sans-serif;
     margin:0;
         line-height: 24px;
    padding-bottom: 24px;

  @media (max-width: 600px) {
    margin:0;
      font: 700 24px/62px Open Sans, sans-serif;
          line-height: 24px;
    padding-bottom: 24px;
  }
`;

const Description = styled.p`
width:50%;

  font: 400 24px/40px Inter, sans-serif;
  margin:0;
  @media (max-width: 600px) {
    width: 100%;
    margin-top: 0px;
      font: 400 16px/25px Inter, sans-serif;
  }
`;

function VisionSection() {
  
  const [content, setContent] = useState({ title: '', body: '' }); // Use state to store content text

useEffect(() => {
  fetch(`${process.env.REACT_APP_API_BASE_URL}/vission-mission`)
    .then(response => response.json())
    .then(result => {
      if (result && result.data && result.data.attributes) {
        setContent({
          title: result.data.attributes.title || 'Our Mission', // Set title from API
          body: result.data.attributes.content || 'No content available.', // Set content from API
        }); // Set the content text
      } else {
        setContent({ title: 'No title available', body: 'No content available.' }); // Handle case with no data
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setContent({ title: 'Failed to load title', body: 'Failed to load content' }); // Set error message
    });
}, []);

  return (
    <SectionWrapper id="vision">
      <Content>
        
        <Title>{content.title}</Title>
        
        <Description>
        {content.body || 'Loading...'}
        </Description>
      </Content>
    </SectionWrapper>
  );
}

export default VisionSection;