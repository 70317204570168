

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TeamSection from '../Components/TeamSection/TeamSection';

const FooterWrapper = styled.footer`
  background-color: rgba(240, 241, 241, 1);
  z-index: 0;
  display: flex;
  width: 100%;
  
  flex-direction: column;
  color:  #0D4A64;

  text-transform: uppercase;
  justify-content: start;
  font: 700 34px/1 Open Sans, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
      padding-top: 0px;
  }
`;

const Title = styled.h2`
  align-self: start;
  padding-left: 200px;
  gap: 10px;
  @media (max-width: 991px) {
    padding-left: 20px;
  }
`;

const TeamImageWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 2.88;
`;

const TeamImage = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: center;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const ClickableArea = styled.div`
  position: absolute;
  cursor: pointer;
  ${({ top, left, width, height }) => `
    top: ${top};
    left: ${left};
    width: ${width};
    height: ${height};
  `}
`;

const BottomSection = styled.div`
  background-color: rgba(255, 255, 255, 1);
  z-index: 0;
  display: flex;
  width: 100%;
  padding-top: 100px;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 600px) {
 padding-top: 0px;
  }
`;

const ContentWrapper = styled.div`
  align-self: center;
  display: flex;
  align-items: start;
  gap: 35px ;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 991px) {
   gap: 0px ;
    
     text-align: center;
  }
`;

const Column = styled.div`
  display: flex;
  min-width: 280px;
  flex-direction: column;
  justify-content: start;
`;

const ColumnTitle = styled.h3`
  width: 85%;
  color: rgba(13, 74, 100, 1);
  text-transform: capitalize;
  font: 700 20px/32px Inter, sans-serif;
  @media (max-width: 600px) {
    align-self: center;
  }
`;

const Button = styled.button`
  align-self: start;
  background-color: rgba(13, 74, 100, 1);
  margin-top: 32px;
  min-height: 48px;
  gap: 8px;
  color: rgba(255, 255, 255, 1);
  padding: 14px 48px;
  font: 600 18px Roboto Flex, sans-serif;
  border: none;
  cursor: pointer;
  @media (max-width: 600px) {
    padding: 0 20px;
     margin-top: 0px;
     align-self: center;
  }
`;

const ContactInfo = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 25px;
  text-transform: capitalize;
  @media (max-width: 600px) {
  margin-top: 0px;
    
  }
`;

const SocialLink = styled.a`
  display: flex;
      margin-top: -31px;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: inherit;
  @media (max-width: 600px) {
    align-self: center;
  }
`;

const SocialIcon = styled.img`

`;

const FooterStrip = styled.div`
  background-color: rgba(1, 39, 56, 1);
  display: flex;
  min-height: 101px;
  margin-top: 96px;
 
  justify-content:center;
  align-items: center; /* Align text vertically */
  padding-left: 20px; /* Adjust padding as needed */
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding-left: 10px;
  }

  span {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    color: white; /* Adjust text color if needed */
    text-align: left;
    text-transform: capitalize;
    @media (max-width: 600px) {
 font-family: 'Open Sans', sans-serif;
font-size: 12px;
font-weight: 700;
line-height: 32px;
text-align: left;

      
    }
@media (max-width: 330px) {
font-size: 11px;
  }
  }
`;

function Footer() {
  const navigate = useNavigate();
  const [scrollToTop, setScrollToTop] = useState(false);
  
  const handlePitchClick = () => {
    setScrollToTop(true);
  };

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0);
      navigate('/form');
      setScrollToTop(false); // Reset state after navigation
    }
  }, [scrollToTop, navigate]);

  const location = "8229 Boone Blvd, Suite 325, Vienna, VA 22182";
  const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(location)}`;

  const openLinkedInProfile = (url) => {
    window.open(url, '_blank');
  };

  return (
    <FooterWrapper id="team">
    <TeamSection/>
      <BottomSection>
        <ContentWrapper>
          <Column>
            <ColumnTitle>Unlock Your startup Potential</ColumnTitle>
            <Button onClick={handlePitchClick}>Pitch To Us</Button>
          </Column>
          <Column>
            <ColumnTitle>Connect</ColumnTitle>
            <ContactInfo>
              <a style={{textTransform:"lowercase",textDecoration:"underline",color: 'rgba(13,74,100,1)' }}href="mailto:hi@rdv.studio">hi@rdv.studio,</a>
              <br />
              <strong>301.306.5102</strong>
              <br />
              <br />
            </ContactInfo>
            <SocialLink
              href="https://www.linkedin.com/company/radiant-digital-ventures-studio/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <SocialIcon loading="lazy" src="./LinkedinBlue.png" alt="LinkedIn" />
            </SocialLink>
          </Column>
          <Column>
            <ColumnTitle>Where to Find Us</ColumnTitle>
            <ContactInfo>
            
              8229 Boone Blvd, Suite 325
              <br />
              Vienna, VA 22182
              <br />
              <a
                href={googleMapsUrl}
                style={{ textDecoration: 'underline', color: 'rgba(13,74,100,1)' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                View on Map
              </a>
            </ContactInfo>
          </Column>
        </ContentWrapper>
        <FooterStrip>
        <span>@2024 Radiant Digital Ventures All Copyrights Reserved</span>
      </FooterStrip>
      </BottomSection>
    </FooterWrapper>
  );
}

export default Footer;
