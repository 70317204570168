

// import React, { useState, useEffect } from 'react';
// import Logo from './Logo';
// import './Header.css';
// import Navigation from './Navigation';
// import { useNavigate } from 'react-router-dom';
// import { FaBars, FaTimes } from 'react-icons/fa'; // Importing hamburger and close icons

// const Header = () => {
//   const [isNavOpen, setIsNavOpen] = useState(false); // State to manage hamburger menu
//   const [isMobile, setIsMobile] = useState(false); // State to manage screen size
//   const navigate = useNavigate();

//   const handlePitchClick = () => {
//     navigate('/form');
//   };

//   // Function to check if screen width is less than or equal to 600px
//   const checkScreenWidth = () => {
//     setIsMobile(window.innerWidth <= 600);
//   };

//   useEffect(() => {
//     checkScreenWidth(); // Check the screen size initially

//     // Add event listener to handle window resize
//     window.addEventListener('resize', checkScreenWidth);

//     // Cleanup the event listener on component unmount
//     return () => {
//       window.removeEventListener('resize', checkScreenWidth);
//     };
//   }, []);

//   const toggleNav = () => {
//     setIsNavOpen(!isNavOpen); // Toggling the nav state
//   };

//   return (
//     <header className="header">
//       <div className="header-content">
//         <img
//           src="https://cdn.builder.io/api/v1/image/assets/TEMP/95fef97434272419a2b97f11299d0bb507b56369a60d5257ee5cb8d450dfe1ea?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66"
//           alt="Background"
//           className="header-background"
//         />
//         <div className="header-text">
//           <h1 className="header-title">Fueling Innovation.</h1>
//           <h2 className="header-subtitle">Backing Disruptive Startups</h2>
//           <button className="cta-button" onClick={handlePitchClick}>PITCH TO US</button>
//         </div>
//       </div>
//       <div className="logo-nav-container">
//         <Logo />
//         {isMobile ? (
//           <button className="hamburger" onClick={toggleNav}>
//             {isNavOpen ? <FaTimes /> : <FaBars />} {/* Show hamburger or close icon */}
//           </button>
//         ) : (
//           <Navigation /> // Show full navigation on larger screens
//         )}
//         {isMobile && isNavOpen && <Navigation />} {/* Show Navigation only when isNavOpen is true */}
//       </div>
//     </header>
//   );
// };

// export default Header;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import './Header.css';
import Navigation from './Navigation';
import { useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; // Importing hamburger and close icons

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false); // State to manage hamburger menu
  const [isMobile, setIsMobile] = useState(false); // State to manage screen size
  const [title, setTitle] = useState('Fueling Innovation.');
  const [subTitle, setSubTitle] = useState('Backing Disruptive Startups');
  const [buttonText, setButtonText] = useState('Pitch To Us');
  const navigate = useNavigate();

  const handlePitchClick = () => {
    navigate('/form');
  };

  // Function to check if screen width is less than or equal to 600px
  const checkScreenWidth = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  useEffect(() => {
    checkScreenWidth(); // Check the screen size initially

    // Add event listener to handle window resize
    window.addEventListener('resize', checkScreenWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/banner-content`);
        const result = await response.json();
        const { title, subTitle, ButtonText } = result.data.attributes || {};

        setTitle(title || 'Fueling Innovation.');
        setSubTitle(subTitle || 'Backing Disruptive Startups');
        setButtonText(ButtonText || 'Pitch To Us');
      } catch (error) {
        console.error('Error fetching banner content:', error);
      }
    };

    fetchData();
  }, []);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen); // Toggling the nav state
  };

  return (
    <header className="header">
      <div className="header-content">
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/95fef97434272419a2b97f11299d0bb507b56369a60d5257ee5cb8d450dfe1ea?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66"
          alt="Background"
          className="header-background"
        />
        <div className="header-text">

          <h1 className="header-title">{title}</h1>
          <h2 className="header-subtitle">{subTitle}</h2>
          <CTAButton onClick={handlePitchClick}>{buttonText}</CTAButton>
        </div>
      </div>
      <div className="logo-nav-container">
        <Logo />
        {isMobile ? (
          <button className="hamburger" onClick={toggleNav}>
            {isNavOpen ? <FaTimes /> : <FaBars />} {/* Show hamburger or close icon */}
          </button>
        ) : (
          <Navigation /> // Show full navigation on larger screens
        )}
        {isMobile && isNavOpen && <Navigation />} {/* Show Navigation only when isNavOpen is true */}
      </div>
    </header>
  );
};

export default Header;

// Styled component for CTA button
const CTAButton = styled.button`
  width: 236px;
  height: 53px;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-align: center;
  color: #0c3343;
  padding: 16px 48px;
  cursor: pointer;
  border-radius: 4px;
  text-transform: capitalize;

  @media screen and (max-width: 600px) {
    font-size: 15.18px;
    font-weight: 500;
    line-height: 15.18px;
    letter-spacing: 0.15183334052562714px;
    width: 180px;
    height: 40px;
    padding: 12.15px 0;
  }
`;

