import React from 'react';
import styled from 'styled-components';

const HeroWrapper = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;


    padding: 80px 0px 80px 200px;
  align-items: flex-start;
  font-weight: 400;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 0px 40px 24px;
 
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  width: 526px;
  max-width: 100%;
  flex-direction: column;
  justify-content: flex-start;
`;

const Heading = styled.h1`
  display: flex;
  width: 100%;
  flex-direction: column;
  letter-spacing: 1.2px;
  justify-content: flex-start;
  font: 32px Poppins, sans-serif;
  font-weight: 700;
  margin-bottom: 18px;
  text-transform: Capitalize;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 24px;
  }
`;

const HeadingPart = styled.span`
  color: ${props => props.color};
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Description = styled.p`
  color: #fff;
  letter-spacing: 0.69px;
  margin-top: 18px;
  margin:0;
  font: 20px/30px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeroSection = () => (
  <HeroWrapper>
    <BackgroundImage loading="lazy" src="./Head.png" alt="Hero background" />
    <ContentWrapper>
      <Heading>
        <HeadingPart color="#fff">We empower tomorrow’s</HeadingPart>
        <HeadingPart color="#91c46b">innovators </HeadingPart>
      </Heading>
      <Description>
      Pitch your ideas, connect with investors, and bring your startup dreams to life. 
      </Description>
    </ContentWrapper>
  </HeroWrapper>
);

export default HeroSection;