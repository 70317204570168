// import React from "react";
// import styled from "styled-components";

// function CompanyLogo({ name, logo, logo1, logo2 ,url,tagline }) {
//   if (logo1 && logo2) {
//     return (
//       <StyledDoubleLogoWrapper>
//       <a href={url} target="_blank" rel="noopener noreferrer">
//         <img src={logo1} alt={`${name} logo part 1`} className="logo-part" />
//         <img src={logo2} alt={`${name} logo part 2`} className="logo-part" />
//         </a>
//       </StyledDoubleLogoWrapper>
//     );
//   }
//   return (
//     <StyledSingleLogoWrapper>
//     <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
//       <img src={logo} alt={`${name} logo`} className="logo-image" />
   
//       {name === "BosonQ Psi" && <span className="company-name">{name}</span>}
//       </a>
//     </StyledSingleLogoWrapper>
//   );
// }

// const StyledSingleLogoWrapper = styled.div`
//   display: flex;
 
//   align-items: center;
//   gap: 8px;
//   color: #000;
//   font: 500 25px/1.5 Inter, sans-serif;

//   .logo-image {
//     width: 48px;
//     height: 48px;
//     object-fit: contain;
//     border-radius: 25px;
//   }

//   &:nth-child(2) .logo-image {
//     width: 167px;
//     height: 69px;
//     border-radius: 0;
//   }
// `;

// const StyledDoubleLogoWrapper = styled.div`
//   display: flex;
//   gap: 4px;
//   overflow: hidden;
//   width: 151px;

//   .logo-part:first-child {
//     width: 37px;
//     height: 47px;
//   }

//   .logo-part:last-child {
//     width: 110px;
//     height: 31px;
//     align-self: center;
//   }
// `;


// export default CompanyLogo;

import React from "react";
import styled from "styled-components";

function CompanyLogo({ name, logo, logo1, logo2, url, tagline }) {
  if (logo1 && logo2) {
    return (
      <StyledDoubleLogoContainer>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <StyledDoubleLogoWrapper>
            <img src={logo1} alt={`${name} logo part 1`} className="logo-part" />
            <img src={logo2} alt={`${name} logo part 2`} className="logo-part" />
          </StyledDoubleLogoWrapper>
        </a>
        {tagline && <StyledTagline>{tagline}</StyledTagline>}
      </StyledDoubleLogoContainer>
    );
  }
  return (
    <StyledSingleLogoContainer>
      <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
        <StyledSingleLogoWrapper>
          <img src={logo} alt={`${name} logo`} className="logo-image" />
          {name === "BosonQ Psi" && <span className="company-name">{name}</span>}
        </StyledSingleLogoWrapper>
      </a>
      {tagline && <StyledTagline>{tagline}</StyledTagline>}
    </StyledSingleLogoContainer>
  );
}

const StyledSingleLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const StyledDoubleLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const StyledSingleLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #000;
  font: 500 25px/1.5 Inter, sans-serif;

  .logo-image {
    width: 48px;
    height: 48px;
    object-fit: contain;
    border-radius: 25px;
  }

  &:nth-child(2) .logo-image {
    width: 167px;
    height: 69px;
    border-radius: 0;
  }
`;

const StyledDoubleLogoWrapper = styled.div`
  display: flex;
  gap: 4px;
  overflow: hidden;
  width: 151px;

  .logo-part:first-child {
    width: 37px;
    height: 47px;
  }

  .logo-part:last-child {
    width: 110px;
    height: 31px;
    align-self: center;
  }
`;

const StyledTagline = styled.span`
  font-size: 14px;
  color: #555;
  text-align: center;
  margin-top: 4px;
`;

export default CompanyLogo;
