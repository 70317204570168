// import React from 'react';
// import styled from 'styled-components';

// const SectionWrapper = styled.section`
//   background-color: rgba(255, 255, 255, 1);
//   position: relative;
//   z-index: 0;
//   display: flex;
//   width: 100%;
//   align-items: flex-start;
//   gap: 40px 100px;
//   color: rgba(27, 94, 91, 1);
//   justify-content: start;
//   @media (max-width: 991px) {
//     max-width: 100%;
    
//   }
// `;

// const BackgroundImage = styled.img`
//   aspect-ratio: 0.66;
//   object-fit: contain;
//   object-position: center;
//   width: 765px;
//   z-index: 0;
//   min-width: 240px;
//   margin: auto 0;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const ContentWrapper = styled.div`
//   align-self: start;
//   position: absolute;
//   z-index: 0;
//   display: flex;
//   min-width: 240px;
//   align-items: center;
//   justify-content: start;
//   right: 29px;
//   top: 159px;
//   width: 826px;
//   @media (max-width: 991px) {
//     max-width: 100%;
//      top: 118px;
//   }
// `;

// const ContentBackground = styled.div`
//   background-color: rgba(13, 74, 100, 1);
//   z-index: 0;
//   display: flex;
//   min-width: 240px;
//   height: 877px;
//   gap: 10px;
//   width: 826px;
//   margin: auto 0;
//   @media (max-width: 991px) {
  
//                margin-left: 61px;
//         max-width: 573px;
      
    
   
  
//   }
// `;

// const Content = styled.div`
//   background-color: rgba(240, 241, 241, 1);
//   align-self: start;
//   position: absolute;
//   z-index: 0;
//   display: flex;
//   min-width: 240px;
 
//   align-items: center;
//   gap: 10px;
//   justify-content: start;
//   left: -20px;
//   top: -20px;
 
 
//   padding: 93px 150px;
//   @media (max-width: 991px) {
  
//         left: 50px;
//         height: 877px;
       
      
       
//         overflow: scroll;
//         padding: 0px 20px;
//         scrollbar-width: none;
   
//   }
//           @media (max-width: 430px) {
  
//         left: 50px;
//         height: 877px;
       
//       max-width: 318px;
//         height: 480px;
//         overflow: scroll;
//         padding: 0px 20px;
//         scrollbar-width: none;
   
//   }
//             @media (max-width: 389px) {
  
//         left: 50px;
//         height: 877px;
       
//       max-width: 275px;
//         height: 480px;
//         overflow: scroll;
//         padding: 0px 20px;
//         scrollbar-width: none;
   
//   }
// `;

// const TextContent = styled.div`
//   align-self: stretch;
//   display: flex;
//   min-width: 240px;
//   width: 525px;
//   flex-direction: column;
//   justify-content: start;
//   margin: auto 0;
// `;

// const Title = styled.h2`
//   text-transform: uppercase;
//   font: 700 34px/62px Open Sans, sans-serif;
// `;

// const Description = styled.p`

//   margin-top: 32px;
//   font: 400 24px/40px Inter, sans-serif;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// function FocusAreaSection() {
//   return (
//     <SectionWrapper id="focus">
//       <BackgroundImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/52961f9501b9a36897346922fca9e7456c37dfcd5a90354b6a66cecd8a2f078e?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" />
//       <ContentWrapper>
//         <ContentBackground />
//         <Content>
//           <TextContent>
//             <Title>Focus AREA</Title>
//             <Description>
//               RDV and RDS bring deep expertise in federal and commercial digital transformation, serving clients across prominent sectors. We support startups applying innovative technologies to address major challenges in cybersecurity, sensors, cloud/data infrastructure, and consumer experience (CX). We see significant opportunities for AI, automation, and novel data management tools to create dramatic customer impact. RDV focuses on pre-revenue startups, typically founder self-funded or with initial seed investments, becoming the first institutional investor.
//             </Description>
//           </TextContent>
//         </Content>
//       </ContentWrapper>
//     </SectionWrapper>
//   );
// }

// export default FocusAreaSection;

// import React from "react";
// import styled from "styled-components";
// import ContentSection from "./ContentSection";

// const FocusAreaSection = () => {
//   return (
//     <StyledFocusArea>
//       <ContentWrapper>
//         <StyledImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/897216920354c4d2910f034340f296aa234f06d30a3baed016641400aa038425?placeholderIfAbsent=true&apiKey=aafae26bdbcc40e0829623d48788b669" alt="Focus Area Illustration" />
//         <ContentSection />
//       </ContentWrapper>
//     </StyledFocusArea>
//   );
// };

// const StyledFocusArea = styled.section`
//   background-color: #fff;
//   display: flex;
//   flex-direction: column;
//   overflow: hidden;
//   color: #0d4a64;
// `;

// const ContentWrapper = styled.div`
//   display: flex;
//   width: 100%;
//   align-items: center;
//   justify-content: space-between;

//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const StyledImage = styled.img`
//   aspect-ratio: 0.9;
//   object-fit: contain;
//   object-position: center;
//   width: 50%;
//   align-self: stretch;
//   min-width: 240px;
//   margin: auto 0;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// export default FocusAreaSection;



import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import TechnologyCards from './TechnologyCard/TechnologyCard';
const TitleAboveImage = styled.h2`
  text-transform: capitalize;
  font: 700 34px/62px Open Sans, sans-serif;
  margin: 0;
  padding:0 24px;
  padding-top: 40px;
  
  @media (min-width: 601px) {
    display: none; /* Hide this title on screens larger than 600px */
  }
  
  @media (max-width: 600px) {
    margin: 0;
    font: 700 24px/62px Open Sans, sans-serif;
    display: block; /* Show this title only on screens 600px or smaller */
     line-height: 24px;
    padding-bottom: 24px;
  }
`;

const TitleAboveContent = styled.h2`
  text-transform: capitalize;
  font: 700 34px/62px Open Sans, sans-serif;
  margin: 0;
      line-height: 24px;
    padding-bottom: 24px;

  @media (max-width: 600px) {
    display: none; /* Hide this title on screens 600px or smaller */
  }
`;

export default function FocusAreaSection() {

  const [content, setContent] = useState({ title: '', body: '' }); // Use state to store content text

useEffect(() => {
  fetch(`${process.env.REACT_APP_API_BASE_URL}/focus-area`)
    .then(response => response.json())
    .then(result => {
      if (result && result.data && result.data.attributes) {
        setContent({
          title: result.data.attributes.title || 'Focus Areas', // Set title from API
          body: result.data.attributes.content || 'No content available.', // Set content from API
      }); // Set the content text
      } else {
        setContent({ title: 'No title available', body: 'No content available.' }); // Handle case with no data
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setContent({ title: 'Failed to load title', body: 'Failed to load content' }); // Set error message
    });
}, []);


  return (
    <FocusArea id="focus">
      <Div>
      <TitleAboveImage>{content.title}</TitleAboveImage>
        <Img
          loading="lazy"
          src="/Focusarea_Image.png"
        />
        <Div2>
          <Div3>
            <Div4>
            <TitleAboveContent>{content.title}</TitleAboveContent>
              {/* <FocusArea2>Focus Areas</FocusArea2> */}
              <Content>
              {content.body || 'Loading...'} 
           
              </Content>
              <div style={{padding: '24px 0px',paddingBottom: '40px'}}>
              <TechnologyCards  />
              </div>
          
        
            </Div4>
            
          </Div3>
        </Div2>
      </Div>
    </FocusArea>
  );
}

const FocusArea = styled.div`
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: rgba(13, 74, 100, 1);
`;

const Div = styled.div`
  display: flex;
  width: 100%;

  align-items: stretch; /* Stretch both items to the height of the parent */
  justify-content: space-between;

  @media (max-width: 600px) {
  display:block;

  }
`;

const Img = styled.img`
  width: 50%;
  object-fit: cover; /* Cover the area without losing aspect ratio */
  @media (max-width: 600px) {
    width:90%;
    padding: 0px 20px;
    height: auto; /* Allow image to resize properly on smaller screens */
  }
`;

const Div2 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Div3 = styled.div`
 
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 80px 50px;
 
  height: 100%;
  @media (max-width: 991px) {
    padding: 20px;
    border: none;
  }
`;

const Div4 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const FocusArea2 = styled.div`
  text-transform:captalize;
  font: 700 34px/62px Open Sans, sans-serif;
`;

const Content = styled.div`

  font: 400 24px/40px Inter, sans-serif;
  @media (max-width: 600px) {
    max-width: 100%;
    border:none;
      margin-top: 0px;
       font: 400 16px/25px Inter, sans-serif;
  }
`;
