import React from "react";
import styled from "styled-components";

function TeamMemberCard({ name, role, imageSrc, linkedInSrc , linkedInSrc2 }) {
  return (
    <StyledTeamMemberCard>
      <img className="member-image" src={imageSrc} alt={`${name} - ${role}`} loading="lazy" />
      <div className="member-info">
        <h3 className="member-name">{name}</h3>
        <p className="member-role">{role}</p>
      </div>
   
    <a href={linkedInSrc2} className="linkedin-link" target="_blank" rel="noopener noreferrer" aria-label={`${name}'s LinkedIn profile`}>
    <img className="linkedin-icon" src={linkedInSrc} alt={`${name} LinkedIn`} loading="lazy" />
  </a>
    </StyledTeamMemberCard>
  );
}

const StyledTeamMemberCard = styled.article`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: flex-start;
  width: 275px;
  align-items: center;

  .member-image {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 100%;
    @media (max-width: 600px) {
      width: 70%;
    }
  }

  .member-info {
    display: flex;
    margin-top: 16px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }

  .member-name {
    font-size: 24px;
    font-weight: 700;
    line-height: 48px;
    margin: 0;
    text-transform: capitalize;





  }

  .member-role {
    font-size: 18px;
    font-weight: 400;
   
    margin: 0;
    text-align: center;
    font-family: Open Sans, sans-serif;
    text-transform: capitalize;

line-height: 24px;
text-align: center;

    
  }

  .linkedin-icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 50px;
    align-self: center;
    margin-top: 16px;
  }
`;

export default TeamMemberCard;