import React from "react";
import styled from "styled-components";
import CompanyLogo from "./CompanyLogo";

const partnerCompanies = [
  { name: "BosonQ Psi",   url: 'https://www.bosonqpsi.com/',
    tagline: 'Building the Future with Quantum-powered Simulations', logo: "https://cdn.builder.io/api/v1/image/assets/TEMP/180d10016cbaea3392f6fff21e3c207c43b4be7c600a85b7786d3349f8e2844d?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" },
  { name: "Company 2",     url: 'https://www.tracklynk.com/',
    tagline: 'Real-time location services for Oil and Gas facilities',logo: "https://cdn.builder.io/api/v1/image/assets/TEMP/f2c7ab7da49b978dcd4391c51624e77edc8772244e9dece2f467e96dc28964c6?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" },
  { name: "Privly",     url: 'https://www.privly.tech/',
    tagline: 'No-code data anonymization means',logo1: "https://cdn.builder.io/api/v1/image/assets/TEMP/4fd59104a2a9fa9d74c84738ed73ef6c2616f6785a5bf0f355db3af2c88d56d7?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66", logo2: "https://cdn.builder.io/api/v1/image/assets/TEMP/e31ad6b280acbd3dbba78a9fa54e0debc732d144650365b2a06178fca806193d?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" }
];

function PartnerCompanies() {
  return (
    <StyledPartnerCompanies>
      <div className="container">
        <h2 className="section-title">Sample Potfolio</h2>
        <div className="logo-grid" >
          {partnerCompanies.map((company, index) => (
            <CompanyLogo key={index} {...company} />
          ))}
        </div>
      </div>
    </StyledPartnerCompanies>
  );
}

const StyledPartnerCompanies = styled.section`
  background-color: #f0f1f1;
  padding: 96px 0;
  @media (max-width: 600px) {
    padding: 46px 0;
  }

  .container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section-title {
    color: #475467;
    font: 400 28px Poppins, sans-serif;
    text-align: center;
    @media (max-width: 600px) {
      font: 400 19px Poppins, sans-serif;
    } /* Missing closing brace added here */
  }

  .logo-grid {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 50px !important;
    margin-top: 32px;
    width: 100%;
    max-width: 716px;
    @media (max-width: 600px) {
      justify-content: center;
    }
  }
`;


export default PartnerCompanies;