import React from "react";
import styled from "styled-components";

function TechnologyCard({ name, images }) {
  return (
    <CardWrapper>
      <ImageContainer>
        {images.map((src, index) => (
          <CardImage key={index} src={src} alt={`${name} illustration ${index + 1}`} loading="lazy" />
        ))}
      </ImageContainer>
      <CardContent>
        <TechnologyName>{name}</TechnologyName>
      </CardContent>
    </CardWrapper>
  );
}

const CardWrapper = styled.article`
  box-shadow: 0px 2px 28px rgba(255, 254, 254, 0.25);
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: center;
 
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  aspect-ratio: 1.63;
  width: 100%;
  overflow: hidden;
`;

const CardImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const CardContent = styled.div`
background-color: #fff;

  display: flex;
  width: 100%;
  flex-direction: column;
  color: #101828;
  justify-content: center;
 
`;

const TechnologyName = styled.h3`
 padding: 16px;
 color: var(--Gray-900, var(--Gray-900, #101828));
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 18.588px; /* 77.449% */
 
  margin: 0;
`;

export default TechnologyCard;