import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'; // For raw HTML support
import remarkGfm from 'remark-gfm';  // For GitHub-flavored markdown

const SectionWrapper = styled.section`

  z-index: 0;
  display: flex;
  
  align-items: center;
    padding-left: 200px;
    gap: 40px;
  
 

 

  @media (max-width: 600px) {
    max-width: 100%;
            flex-wrap: wrap;
            gap: 0px;
              justify-content: start;
              padding: 40px 0px;

  }
`;

const LeftColumn = styled.div`
  align-self: stretch;
  display: flex;
  min-height: 772px;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  margin: auto 0;
  @media (max-width: 600px) {
 display:none;
  }
`;
const TitleWrapper = styled.div`
  @media (min-width: 600px) {
    display: none;
  }
`;

const Title = styled.h2`
  color: rgba(13, 74, 100, 1);
  text-transform: capitalize;
  text-align: center;
  font: 700 34px/48px Open Sans, sans-serif;
  @media (max-width: 600px) {
  padding-left: 24px;
    font: 700 24px/48px Open Sans, sans-serif;
    margin: 0;
     line-height: 24px;
    padding-bottom: 24px;
    
  }
`;

const Infographics = styled.div`
  position: relative;
  display: flex;
 
  max-width: 100%;
  width: 134px;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
const InfographicImage2 = styled.img`
    position: absolute;
    top: 138px;
    height: 276px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  z-index: 0;
  margin-top: ${props => props.marginTop || '0'};
  @media (max-width: 991px) {
    
`;
const InfographicImage3 = styled.img`


  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  z-index: 0;
  margin-top: ${props => props.marginTop || '0'};
  @media (max-width: 991px) {
  
  }
`;
const InfographicImage4 = styled.img`


  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  z-index: 0;
  margin-top: ${props => props.marginTop || '0'};
  @media (max-width: 991px) {
   
  }
`;
const InfographicImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  z-index: 0;
  margin-top: ${props => props.marginTop || '0'};
  @media (max-width: 991px) {
   
  }
`;

const RightColumn = styled.div`
  background-color: rgba(13, 74, 100, 1);
  align-self: stretch;
  display: flex;
  min-width: 240px;
  width: 100%;

  flex-direction: column;
  align-items: start;
  font-family: Open Sans, sans-serif;
  color: rgba(240, 241, 241, 1);
  justify-content: center;
 
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin:0px 20px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 80px  40px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const StepContent = styled.div`
  display: flex;

  max-width: 100%;
  flex-direction: column;
  justify-content: start;
 padding: 0px 55px;

 @media (max-width: 600px) {
 padding: 0px 5px;
`;

const StepTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.24px;
  text-transform: capitalize;
   margin-bottom: 0px;
  @media (max-width: 600px) {
    margin-bottom: 0px;
    font-size: 20px;
  }
 

  &::before {
    content: "${props => props.index + 1}.";
    margin-right: 8px;
  }
`;

const StepDescription = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
  margin-top: 16px;
  @media (max-width: 991px) {
    max-width: 100%;
     font-size: 16px;
     margin:0;
  }
`;

const Divider = styled.img`
  aspect-ratio: 333.33;
  object-fit: contain;
  object-position: center;
  width: 100%;
  align-self: stretch;
  margin-top: 36px;
  @media (max-width: 600px) {
  margin-top: 10px;
   width: 100%;
    aspect-ratio: 333.33;
    
  }
`;
const HighlightedText = styled.span`
  color: #c7dd75;
  letter-spacing: 0.04px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledAnchor = styled.a`
  color: #c7dd75; /* Custom color for anchor tags */
  text-decoration: underline;
  text-transform: capitalize;
  &:hover {
    color: #c7dd75; /* Change color on hover optional*/
  }
`;

function IntakeProcessSection() {
  const navigate = useNavigate();

  const handlePitchClick = () => {
    navigate('/form');
    window.scrollTo(0, 0); // Optional: Scroll to top when navigating
  };
  
  const [steps, setSteps] = useState([]); // Use state to store fetched data

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/intake-processes`)
      .then(response => response.json())
      .then(data => {
        if (data && data.data) {
          const fetchedSteps = data.data.map(item => ({
            title: item.attributes.Title,  // Use 'Title' instead of 'title'
            description: item.attributes.Description,  // Use 'Description' instead of 'description'
          }));
          setSteps(fetchedSteps);
        } else {
          setSteps([{ title: 'No data available', description: '' }]);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setSteps([{ title: 'Failed to load content', description: '' }]);
      });
  }, []);

   // Custom renderer to handle anchor tags
   const renderers = {
    a: ({ href, children }) => (
      <StyledAnchor href={href} rel="noopener noreferrer">
        {children}
      </StyledAnchor>
    ),
  };

  return (
    <SectionWrapper id="process">
    
    <TitleWrapper>
    <Title>Intake Process</Title>
  </TitleWrapper>
      <LeftColumn>
        <Title>
        Intake Process 
        </Title>
        <Infographics>
          <InfographicImage2 loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/acc25b2c8824f4294e6e840fda277805e0a6b051b6570a88cb2c86086ad5b2e8?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" />
          <InfographicImage3 loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/45ea2f63c0034155fee948bbf434376a08d388343bd11c2bc89c2f03d97fb3a6?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt=""  />
          <InfographicImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/02ddf42a5946fb11f443a55d2b3e288d6ba6ed380ed175e35a7b156f248f069e?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" marginTop="74px" />
          <InfographicImage4 loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/37ee405db7eb604e282af0fdc93860b50272a3beaad85827371d66304fd6b116?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" marginTop="74px" />
        </Infographics>
      </LeftColumn>
      <RightColumn>
        <ContentContainer>
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <StepContent marginTop={index > 0 ? '36px' : '0'}>
                <StepTitle index={index}>{step.title}</StepTitle>
                <StepDescription>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} components={renderers}>
                      {step.description}
                    </ReactMarkdown>
                </StepDescription>
              </StepContent>
              {index < steps.length - 1 && <Divider loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7bba2af04441945d502a8d4bc22503c95e33deb09aec24bcc53cc813d54b696e?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" />}
            </React.Fragment>
          ))}
        </ContentContainer>
      </RightColumn>
    </SectionWrapper>
  );
}

export default IntakeProcessSection;
