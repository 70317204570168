

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; 

const NavWrapper = styled.nav`
     background-color: #0D4A64;
  display: flex;

  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 24px 80px;
  @media (max-width: 600px) {
    max-width: 100%;
    padding: 24px 20px;
    justify-content: center;
  }
`;

const Logo = styled.div`
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  justify-content: flex-start;
  margin: auto 0;
  
`;

const LogoImage = styled.img`
  aspect-ratio: ${props => props.ratio};
  object-fit: contain;
  object-position: center;
  width: ${props => props.width};
`;

const NavLinks = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
 
  align-items: center;
  color: #f7f7f7;
  text-transform: uppercase;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 623px; 
  margin: auto 0;
  font: 600 18px/1 'Open Sans', sans-serif;
  @media (max-width: 600px) {
  
   
      flex-direction: column;
      position: absolute;
      top: 80px;
      left: 0;
      background-color: white;
      width: 200px;
      padding: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      z-index: 1;
    
  }
`;

const NavLink = styled.a`
  align-self: stretch;
  padding-bottom: 2px;
  gap: 10px;
 
  margin: auto 0;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

const Navigation = () => {
  const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = useState(false); // State to manage hamburger menu
  const [isMobile, setIsMobile] = useState(false); // State to manage screen size

  // const handleNavigation = (path, sectionId) => {
  //   navigate(path, { replace: true }); // Navigate to the path
  //   setTimeout(() => {
  //     const section = document.getElementById(sectionId);
  //     if (section) {
  //       section.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }, 100); // Slight delay to ensure the page is loaded before scrolling
  // };
  const handleNavigation = (path, sectionId) => {
    navigate(path, { replace: true }); // Navigate to the path
    
    const scrollToSection = () => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      } else {
        // Retry scrolling after a small delay if the section isn't found
        setTimeout(scrollToSection, 100);
      }
    };
  
    // Use requestAnimationFrame to ensure the DOM is ready before scrolling
    requestAnimationFrame(scrollToSection);
  };
  
  const checkScreenWidth = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  useEffect(() => {
    checkScreenWidth(); // Check the screen size initially

    // Add event listener to handle window resize
    window.addEventListener('resize', checkScreenWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen); // Toggling the nav state
  };

  return (
    <NavWrapper>
      <Logo onClick={() => handleNavigation('/', '')}>
      <div className="logo-container">
      <img src="/Logo Frame.png" alt="Logo 1" className="logo-image" />
      
  
    
      </div>
      </Logo>
      {isMobile ? (
        <button className="hamburger" onClick={toggleNav}>
          {isNavOpen ? <FaTimes /> : <FaBars />} {/* Show hamburger or close icon */}
        </button>
      ) : (
        <NavLinks className="main-navigation">
        <NavLink className="nav-link" onClick={() => handleNavigation('/', 'vision')}>Our Mission</NavLink>
        <NavLink  className="nav-link" onClick={() => handleNavigation('/', 'focus')}>Focus Areas</NavLink>
        <NavLink className="nav-link" onClick={() => handleNavigation('/', 'process')}>Intake Process</NavLink>
        <NavLink className="nav-link" onClick={() => handleNavigation('/', 'team')}>Our Team</NavLink>
      </NavLinks>
      )}
      {isMobile && isNavOpen &&   <NavLinks className="main-navigation">
        <NavLink className="nav-link" onClick={() => handleNavigation('/', 'vision')}>Our Mission</NavLink>
        <NavLink  className="nav-link" onClick={() => handleNavigation('/', 'focus')}>Focus Areas</NavLink>
        <NavLink className="nav-link" onClick={() => handleNavigation('/', 'process')}>Intake Process</NavLink>
        <NavLink className="nav-link" onClick={() => handleNavigation('/', 'team')}>Our Team</NavLink>
      </NavLinks>}
   
    </NavWrapper>
  );
};

export default Navigation;

