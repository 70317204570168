// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';

// const stepNumbers = [
//   "https://cdn.builder.io/api/v1/image/assets/TEMP/c378e56be3ffca9fc6873c5d6c4c18274c352cac4aa2b6680fe112c33c94fd24?apiKey=a938513dc279413eaac5ce0f8c637f66",  // Number 1 Icon
//   "https://cdn.builder.io/api/v1/image/assets/TEMP/59fa82e54d39d6c6178456784ed1d42d4ce4ba1d7eae1a764cf75ba9ca571737?apiKey=a938513dc279413eaac5ce0f8c637f66",  // Number 2 Icon
//   "https://cdn.builder.io/api/v1/image/assets/TEMP/a9d90b1b1cc986a5388e3db1eae0713be82e082104a75277c1a5fdab974b2c9f?apiKey=a938513dc279413eaac5ce0f8c637f66"   // Number 3 Icon
// ];

// const SectionWrapper = styled.section`
//   background-color: rgba(13, 74, 100, 1);
//   z-index: 0;
//   display: flex;

//   flex-direction: column;
//   justify-content: start;
//   padding: 80px 50px 80px;
//   @media (max-width: 600px) {
//     max-width: 100%;
//     padding: 40px 24px 40px;
//   }
// `;

// const Content = styled.div`
//   display: flex;
//   width: 100%;
//   flex-direction: column;
//   justify-content: start;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const Header = styled.div`
//   align-self: start;
//   display: flex;
//   align-items: center;
//   justify-content: start;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const Title = styled.h2`
//   color: rgba(240, 241, 241, 1);
//   text-transform:captalize;
//   align-self: stretch;
//   margin: auto 0;
//   font: 700 34px/62px Open Sans, sans-serif;
//   @media (max-width: 600px) {
//     font: 700 24px/62px Open Sans, sans-serif;
    
//   }
// `;

// const StepContainer = styled.div`
//   display: flex;
//   margin-top: 48px;
//   width: 100%;
//   flex-direction: column;
//   color: rgba(252, 247, 241, 1);

//   justify-content: start;
//   font: 700 24px/62px Open Sans, sans-serif;
//   @media (max-width: 600px) {
//     max-width: 100%;
//     margin-top: 0px;
//     font-size: 13px;
//   }
// `;

// const StepText = styled.p`
//   align-self: stretch;
//   margin: auto 0;
//      font-size: 24px;
//      text-transform:capitalize;
//   @media (max-width: 600px) {
//     max-width: 100%;
//      margin: auto 0;
//      align-self: stretch;
//      line-height: 20px;
//      font-size: 16px;
//   }
// `;

// const StepIcon = styled.img`
//   aspect-ratio: 1;
//   object-fit: contain;
//   object-position: center;
//   width: 80px;
//   max-width: 100%;   height: auto;
//   align-self: stretch;
//   margin: auto 0;
//     @media (max-width: 600px) {
//     max-width: 20px;
 
//   }
// `;

// const StepNumber = styled.img`
//   aspect-ratio: 1;
//   object-fit: contain;
//   object-position: center;
//   width: 125px;
//   align-self: stretch;
//   margin: auto 0;

//   @media (max-width: 600px) {
 
//     width: 50px;
//     flex: 0; /* Ensure StepNumber stays a fixed width on mobile */
//   }
// `;

// const StepDescription = styled.p`
//   display: none; /* Hidden by default */


//   color: rgba(13, 74, 100, 1); /* Dark text color */
//     width:100%;
//     max-width:100%;
//      font-size: 20px;
//   @media (max-width: 600px) {
//     width: 100%;
//  line-height: 20px;
//  padding-top: 40px;
//  font-size: 16px;
 

    
//   }
// `;

// const Step = styled.div`
//   border-radius: 140px;
//   display: flex;
//   margin-top: ${(props) => props.marginTop || '0'};
//   justify-content: space-between;
//   flex-wrap: wrap;
//   padding: 10px 30px 10px 90px;
//   border: 2px solid rgba(255, 255, 255, 1);
//   background-color: rgba(13, 74, 100, 1);
//   color: rgba(252, 247, 241, 1);
//   transition: background-color 0.3s ease, color 0.3s ease;
//   @media (max-width: 600px) {
//     margin-top: 30px;
//   }

//   &:hover {
//     background-color: rgba(252, 247, 241, 1);
//     color: rgba(13, 74, 100, 1);
//     border: 2px solid rgba(13, 74, 100, 1);
//     border-radius: 6px;
//     border-left: 10px solid #9BC779;

//     ${StepText} {
//       font-weight: bold;
//       color: #1B5E5B;
//       font-size: 24px;
//       @media (max-width: 600px) {
//         font-size: 16px;
//       }
   
//     }

//     ${StepIcon}, ${StepNumber} {
//       filter: invert(1);
//     }

//     ${StepIcon} {
//       display: none;
//     }

//     ${StepDescription} {
//       display: block;
//       color: #1B5E5B;
  
//       text-transform: none;
//       margin-top: -40px;
//       @media (max-width: 600px) {
//         font-size: 14px;
//           color: rgba(13, 74, 100, 0.9);
//       }
//     }
//   }

//   @media (max-width: 600px) {
//     max-width: 100%;
//     padding: 10px 20px 10px 20px;
//     flex-direction: row; /* Align items side by side on mobile */
//     align-items: center;
//     justify-content: space-between;
//   }
// `;

// const StepContent = styled.div`
//   align-self: stretch;
//   display: flex;
 
//   align-items: center;
//   gap: 20px;
//   justify-content: start;
//   flex-wrap: wrap;
//   margin: auto 0;

//   @media (max-width: 600px) {
//     width: auto;
//     flex-wrap: nowrap;
//     margin: auto 0;
//     gap: 10px; /* Adjust gap between StepIcon and StepText on mobile */
//     flex: 1;
//   }
// `;




// function ApproachSection() {
//   const [steps, setSteps] = useState([]); // Use state to store fetched data

//   useEffect(() => {
//     fetch(`${process.env.REACT_APP_API_BASE_URL}/steps-approaches/`)
//       .then(response => response.json())
//       .then(data => {
//         if (data && data.data) {
//           const fetchedSteps = data.data.map((item, index) => ({
//             text: item.attributes.text,  // Correctly map 'text'
//             description: item.attributes.description,  // Correctly map 'description'
//             numberSrc: stepNumbers[index] || stepNumbers[0]  // Use the provided number icons
//           }));
//           setSteps(fetchedSteps);
//         } else {
//           setSteps([{ text: 'No data available', description: '', iconSrc: '', numberSrc: '' }]);
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//         setSteps([{ text: 'Failed to load content', description: '', iconSrc: '', numberSrc: '' }]);
//       });
//   }, []);
  
//   return (
//     <SectionWrapper>
//       <Content>
//         <Header>
//           <Title>3-Step Approach </Title>
//         </Header>
//         <StepContainer>
//           {steps.map((step, index) => (
//             <Step key={index} marginTop={index > 0 ? '30px' : '0'}>
//               <StepContent>
//               <StepIcon
//                   loading="lazy"
//                   src="https://cdn.builder.io/api/v1/image/assets/TEMP/0ca93e38824a686abc6608d357a229b9e83141583cc7673cead46a5d62770430?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66"
//                   alt=""
//                 />
//                 <StepText>{step.text}</StepText>
//               </StepContent>
//               <StepNumber loading="lazy" src={step.numberSrc} alt="" />
//               <StepDescription>{step.description}</StepDescription>
//             </Step>
//           ))}
//         </StepContainer>
//       </Content>
//     </SectionWrapper>
//   );
// }

// export default ApproachSection;


import React, { useState, useEffect } from 'react';

import styled from "styled-components";
import { StepCard } from "./StepCard";
const stepNumbers = [
  "https://cdn.builder.io/api/v1/image/assets/TEMP/55f31de4442869c78ded32e03f9b3b69e8d50832926eff1aff0804d69ac4e798?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66",  // Number 1 Icon
  "https://cdn.builder.io/api/v1/image/assets/TEMP/26b7ad381f78f0911d4b9f505869e26de7cbb42b130adcd11065f629f79ed17e?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66",  // Number 2 Icon
  "https://cdn.builder.io/api/v1/image/assets/TEMP/cfdf4bfafc34606855d268430c4947147171cab40fcf705bc49ff9c39c1de743?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66"   // Number 3 Icon
];

// const stepData = [
//   {
//     icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/55f31de4442869c78ded32e03f9b3b69e8d50832926eff1aff0804d69ac4e798?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66",
//     title: "Conduct Proof-of-Concepts (POCs)",
//     description: "Extremely valuable for market validation and customer feedback."
//   },
//   {
//     icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/26b7ad381f78f0911d4b9f505869e26de7cbb42b130adcd11065f629f79ed17e?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66",
//     title: "Deploy working pilots in customer environments",
//     description: "Crucial for establishing operational viability and acceptance for the product and documented customer satisfaction."
//   },
//   {
//     icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cfdf4bfafc34606855d268430c4947147171cab40fcf705bc49ff9c39c1de743?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66",
//     title: "Create Early Revenue Streams",
//     description: "Convert pilots to paying customers and cross-sell to adjacent markets."
//   }
// ];

function ApproachSection() {
    const [steps, setSteps] = useState([]); // Use state to store fetched data

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/steps-approaches/`)
      .then(response => response.json())
      .then(data => {
        if (data && data.data) {
          const fetchedSteps = data.data.map((item, index) => ({
            text: item.attributes.text,  // Correctly map 'text'
            description: item.attributes.description,  // Correctly map 'description'
            numberSrc: stepNumbers[index] || stepNumbers[0]  // Use the provided number icons
          }));
          setSteps(fetchedSteps);
        } else {
          setSteps([{ text: 'No data available', description: '', iconSrc: '', numberSrc: '' }]);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setSteps([{ text: 'Failed to load content', description: '', iconSrc: '', numberSrc: '' }]);
      });
  }, []);
  return (
    <section className="three-step-approach">
      <StepContainer>
        <HeaderContent>
        {/*
          <LogoContainer>
            <StepIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/47075d87f2f97f5cc3235c2224d573d1c367369bc513556ce91d8045cbce0fc9?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" />
            <StepIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/20599df6295a827f2353f1488c5687818ed1fcac467efd21db46a0a0d948b3cd?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" />
            <StepIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/67b371259f3ab6d9984419c36e3e0ee8ee5176bd48d0c317e79d84b901bc85f4?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" />
            <StepIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7dc5987029598930a5cf0d7dd51201f70194eb36db039d63f99824de04777e23?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" />
            <StepIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d79dba980521b478b4bde48f2c813bde69daa4d0b603c6f3409a2a671eecf07c?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" />
          </LogoContainer>
          */}
          <SectionTitle>3-Step Approach</SectionTitle>
        </HeaderContent>
        <CardsContainer>
          {steps.map((step, index) => (
            
            <StepCard
              key={index}
              icon={step.numberSrc}
              title={step.text}
              description={step.description}
            />
            
          ))}
        </CardsContainer>
      </StepContainer>
    </section>
  );
}

const StepContainer = styled.div`
  background-color: rgba(13, 74, 100, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 200px;
    @media (min-width: 1500px) {
  padding-bottom: 0px;
    
  }
  @media (max-width: 991px) {
    padding: 40px 24px;
  }
`;

const HeaderContent = styled.header`
  display: flex;
  align-items: center;
  justify-content: Center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  padding: 1px 13px 1px 23px;
  @media (max-width: 991px) {
    padding-left: 20px;
  }
`;

const StepIcon = styled.img`
  object-fit: contain;
  object-position: center;
  width: 24px;
  aspect-ratio: 1;
`;

const SectionTitle = styled.h2`
  color: rgba(240, 241, 241, 1);
  font: 700 34px/62px Open Sans, sans-serif;
  margin: 0;
     line-height: 24px;
    padding-bottom: 24px;

  @media (max-width: 600px) {
    font: 700 24px/62px Open Sans, sans-serif;
     line-height: 24px;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  margin-top: 32px;
  width: 100%;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default ApproachSection;
