import * as React from "react";
import styled from "styled-components";

export function StepCard({ icon, title, description }) {
  return (
    <CardContainer>
      <CardContent>
        <CardIcon loading="lazy" src={icon} alt="" />
        <TextContent>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </TextContent>
      </CardContent>
    </CardContainer>
  );
}

const CardContainer = styled.article`
  display: flex;
  min-width: 240px;
min-Height: 410px;
  

  gap: 30px;
  justify-content: center;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
  text-align: center;
  @media (max-width: 600px) {
    min-height: auto;
  }
`;

const CardContent = styled.div`
  display: flex;
  min-width: 240px;
  width: 100%;
  flex-direction: column;
 
  flex: 1;
  flex-basis: 0%;
`;

const CardIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 141px;
  align-self: center;
  max-width: 100%;
`;

const TextContent = styled.div`
  display: flex;
  margin-top: 30px;
  width: 100%;
  flex-direction: column;

`;

const CardTitle = styled.h3`
  color: rgba(252, 247, 241, 1);
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
      align-self: center;
    max-width: 325px;
     font-family: 'Open Sans', sans-serif;

  @media (max-width: 600px) {
    font-size: 20px;
      
  }
`;

const CardDescription = styled.p`
  color: rgba(255, 255, 255, 1);
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  margin: 24px 0 0;
 
   font-family: 'Open Sans', sans-serif;
  @media (max-width: 600px) {
    font-size: 16px;
      
  }
`;