import React from "react";
import styled from "styled-components";
import TechnologyCard from "./TechnologyCard";

const technologies = [
  { name: "DeepTech", images: ["https://cdn.builder.io/api/v1/image/assets/TEMP/fce58507bf0f4e6b60d26e3a28d420c36db637c7eafbdcc3bbe8a60b50b8017f?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66", "https://cdn.builder.io/api/v1/image/assets/TEMP/7549a5483b420d6c209b9de08f5dc44a43a67a77fc34f9f7b481cd9ce35450e1?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66"] },
  { name: "CyberTech", images: ["https://cdn.builder.io/api/v1/image/assets/TEMP/acb153219f6e6afdc6171036c8a0d79f6d1e17c5cc384595da2335cbe966bd58?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66"] },
  { name: "Enterprise Intelligence", images: ["https://cdn.builder.io/api/v1/image/assets/TEMP/dc7e458b59190d986f471b1e45973a85586ae86db6e424c33adfa29f92001ae3?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66", "https://cdn.builder.io/api/v1/image/assets/TEMP/751ab23982820add1f8f3b4a77c842442ce7e0020a52af525725381309dddffb?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66"] },
  { name: "OperationalTech", images: ["https://cdn.builder.io/api/v1/image/assets/TEMP/c5ea1266581ac35cdd3986b3ae04d6eea649f8f5cc2abefd5f8f6352baa622a8?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66", "https://cdn.builder.io/api/v1/image/assets/TEMP/5f2f9ea49e9d236792f5a03718c72878628e2c479d44d0975a13f30863a36e51?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66"] }
];

function TechnologyCards() {
  return (
    <section className="technology-cards">
      <TechnologyCardsContainer>
        <TechnologyCardsWrapper>
          {technologies.map((tech, index) => (
            <TechnologyCard key={index} name={tech.name} images={tech.images} />
          ))}
        </TechnologyCardsWrapper>
      </TechnologyCardsContainer>
    </section>
  );
}

const TechnologyCardsContainer = styled.div`
  background-color: #0d4a64;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 80px 40px;
  @media (max-width: 600px) {
    padding: 40px 20px;
  }
`;

const TechnologyCardsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    max-width: 100%;
      justify-content: center;
  }
`;

export default TechnologyCards;