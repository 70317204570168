

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PartnerLogo from './PartnerLogo';

const SocialProofSection = () => {
  const [partnerData, setPartnerData] = useState([]);
  const [content, setContent] = useState({ title: '', subTitle: '', body: '' });


  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/portfolios`)
      .then((response) => response.json())
      .then((result) => {
        if (result && result.data && result.data[0]?.attributes) {
          const attributes = result.data[0].attributes;

          // Mapping the tags and URLs from the API to the partnerData format
          const mappedPartnerData = [
            {
              name: 'BosonQ Psi',
              logo: './BosonQLogo.png',
              description: attributes.tag1 || 'Building the Future with Quantum-powered Simulations',
              url: attributes.tag1url || 'https://www.bosonqpsi.com',
            },
            {
              name: 'Tracklynk',
              logo: './TracklynkLogo.svg',
              description: attributes.tag2 || 'Real-time location services for Oil and Gas facilities',
              url: attributes.tag2url || 'https://www.tracklynk.com',
            },
            {
              name: 'Privly',
              logo: './privlyLogo.png',
              secondaryLogo: './privlyText.png',
              description: attributes.tag3 || 'No-code data anonymization',
              url: attributes.tag3url || 'https://www.privly.tech/',
            },
          ];

          setPartnerData(mappedPartnerData);
          setContent({
            title: attributes.title || 'Sample Portfolio',
            subTitle: attributes.subTitle ,
            body: attributes || 'No content available.',
          });
        } else {
          setContent({
            title: 'No title available',
            subTitle: 'No subtitle available',
            body: 'No content available.',
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setContent({ title: 'Failed to load title', body: 'Failed to load content' });
      });
  }, []);

  return (
    <StyledSection>
      <Container>
      <Heading>{content.title}</Heading>
      {content.subTitle && (
      <Subheading>{content.subTitle}</Subheading>
      )}
        <LogoGrid>
          {partnerData.map((partner, index) => (
            <PartnerLogo key={index} {...partner} />
          ))}
        </LogoGrid>
      </Container>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  background-color: #f0f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  @media (max-width: 600px) {
    padding: 24px 0;
  }
`;

const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 32px;

  @media (max-width: 600px) {
    padding: 0 24px;
  }
`;

const Heading = styled.h2`
  color: rgba(13, 74, 100, 1);
  text-transform: capitalize;
  font: 700 34px / 48px Open Sans, sans-serif;
  text-align: center;
  margin:0;

      line-height: 18px;
    padding-bottom: 24px;
  @media (max-width: 600px) {
    font: 700 24px Open Sans, sans-serif;
    color: rgba(13, 74, 100, 1);
    text-transform: capitalize;
  }
`;
const Subheading = styled.p`
  color: black;
  font: 400 18px / 28px Open Sans, sans-serif;
  text-align: center;
  margin: 0;
  padding-bottom: 40px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const LogoGrid = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
  gap: 48px;
  justify-content: center;
  flex-wrap: wrap;
`;

export default SocialProofSection;
