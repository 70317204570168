import React from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';
import HeroSection from './HeroSection';

const HeaderWrapper = styled.header`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Header = () => (
  <HeaderWrapper>
    <Navigation />
    <HeroSection />
  </HeaderWrapper>
);

export default Header;