import React from "react";
import styled from "styled-components";

const PartnerLogo = ({ name, logo, secondaryLogo, description, url }) => {
  const handleClick = () => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <StyledPartnerLogo onClick={handleClick}>
    <div style={{ display: "flex",alignSelf: "center"}}>
      <LogoContainer>
        <PrimaryLogo src={logo} alt={`${name} logo`} />
        {name === "BosonQ Psi" && <CompanyName>{name}</CompanyName>}
      </LogoContainer>
      {secondaryLogo && (
        <SecondaryLogo src={secondaryLogo} alt={`${name} secondary logo`} />
      )}
      </div>
      <PartnerDescription>{description}</PartnerDescription>
    </StyledPartnerLogo>
  );
};

const StyledPartnerLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
`;

const PrimaryLogo = styled.img`

  height: 80px;
  
  object-fit: contain;
`;

const CompanyName = styled.span`
  font-family: Inter, sans-serif;
`;

const SecondaryLogo = styled.img`
  width: 119px;
  max-width: 100%;
  object-fit: contain;
  margin-top: 4px;
`;

const PartnerDescription = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 2;
  margin-top: 4px;
  font-family: Inter, sans-serif;
  color: #000;
`;

export default PartnerLogo;