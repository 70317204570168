import React from 'react';

const Logo = () => {
  return (
    <div className="logo-container">
    <img src="/Logo Frame.png" alt="Logo 1" className="logo-image" />
    

  
    </div>
  );
};

export default Logo;