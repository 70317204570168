import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from './Header';
import PitchForm from './PitchForm';
import Footer from './Footer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useLocation } from 'react-router-dom';

const ScrollButton = styled.button`
position: fixed;
bottom: 20px;
right: 20px;
width: 50px; /* Set width */
height: 50px; /* Set height */
padding: 0; /* Remove padding to keep it circular */
font-size: 16px;
border: none;
border-radius: 50%; /* Make it a circle */
background-color:  #91c46b;
color: white; /* Add color to ensure text/icon is visible */
cursor: pointer;
z-index: 100;
display: flex; /* Center content */
align-items: center; /* Center content vertically */
justify-content: center; /* Center content horizontally */
transition: 'opacity 0.3s ease-in-out',
  
  &:hover {
    background-color: #0056b3;
  }
`;
const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};



function Layout() {
  const [isVisible, setIsVisible] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);
  return (
  <LayoutWrapper>
    <Header />
    <main>
      <PitchForm />
    </main>
    <Footer />
    <ScrollButton onClick={scrollToTop} style={{ opacity: isVisible ? '1' : '0', visibility: isVisible ? 'visible' : 'hidden' }}>
    <KeyboardArrowUpIcon style={{ height: '40px', width: '40px', color: 'white' }} />
    {/* <img style={{ height: '40px', width: '40px' }} src="./scroll.jpg" alt="Scroll to Top" /> */}
  </ScrollButton>
  </LayoutWrapper>
  
);
}

export default Layout;
