import React from "react";
import styled from "styled-components";

const technologies = [
  { name: "Deep Tech", image: "https://cdn.builder.io/api/v1/image/assets/TEMP/acac29e49328efe7d10567a280610b201c905e0ef154690e9503b84797c0d05c?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" },
  { name: "Cyber Tech", image: "https://cdn.builder.io/api/v1/image/assets/TEMP/0bc188ec6bb343d36e663fbfe353c029d4f10d20ef705355d1f207f7b28758ff?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" },
  { name: "Enterprise Intelligence", image: "https://cdn.builder.io/api/v1/image/assets/TEMP/4e0276cdff6126ffdc38e2a2fd7bc7815716b0320b9ee5400a2cd7c98e234149?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" },
  { name: "Operational Tech", image: "https://cdn.builder.io/api/v1/image/assets/TEMP/2b911cde20dca888d041487188543cd471bd8e90e2fb80f352c6c8eae58cf091?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" }
];

function TechnologyCards() {
  return (
    <section className="technology-cards">
      <CardContainer>
        <CardWrapper>
          {technologies.slice(0, 2).map((tech, index) => (
            <TechnologyCard key={index} name={tech.name} image={tech.image} />
          ))}
        </CardWrapper>
        <CardWrapper>
          {technologies.slice(2).map((tech, index) => (
            <TechnologyCard key={index} name={tech.name} image={tech.image} />
          ))}
        </CardWrapper>
      </CardContainer>
    </section>
  );
}

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px 41px;
  justify-content: flex-start;
 
  @media (max-width: 991px) {
    max-width: 100%;
     flex-wrap: wrap;
  }
`;

function TechnologyCard({ name, image }) {
  return (
    <Card>
      <CardImage src={image} alt={name} loading="lazy" />
      <CardContent>
        <CardTitle>{name}</CardTitle>
      </CardContent>
    </Card>
  );
}

const Card = styled.article`
  box-shadow: 0 0 6px rgba(147, 146, 146, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;


`;

const CardImage = styled.img`
  aspect-ratio: 1.64;
  object-fit: contain;
  object-position: center;
  width: 100%;
`;

const CardContent = styled.div`
  background-color: #fff;
  padding: 16px;
`;

const CardTitle = styled.h3`
  color: #101828;
  font: 600 24px/1 'Open Sans', sans-serif;
  margin: 0;
  @media (max-width: 991px) {
    font: 600 20px/1 'Open Sans', sans-serif;
`;

export default TechnologyCards;